<!--我的月报-->
<template lang="html">
    <div class="commonWrap">
        <el-row v-if="isShowMenu" type="flex" justify="space-between" class="fixMenu" style="border-color:#006666">
            <el-tooltip class="item" effect="dark" content="排序向上" placement="bottom">
                <el-button type="text" :disabled="sortUpDistable"  icon="el-icon-top" @click="sortUp()"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="排序向下" placement="bottom">
                <el-button type="text" :disabled="sortDownDistable" icon="el-icon-bottom" @click="sortDown()"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="结转到下月" placement="bottom">
                <el-button type="text" :disabled="nextWeekDistable" icon="el-icon-refresh-right" @click="nextWeek()"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="设置优先级" placement="bottom">
                <el-button type="text" :disabled="delDistable" icon="el-icon-warning-outline" @click="setPriority()"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="bottom">
                <el-button type="text" icon="el-icon-delete" @click="habdleDel()"></el-button>
            </el-tooltip>
        </el-row>
        <div class="weekBox">
            <!--上级查看下级进行审核-->
            <el-row  style="margin-bottom:15px;" type="flex" justify="space-between">
                <el-button v-if="userId" style="width:150px" type="warning" size="small" plain icon="el-icon-sort" @click="jumpWeek()">切换周报</el-button>
            </el-row>

            <el-collapse v-model="selectWeek">
                <el-card v-for="(item,index) in weekArray" :key="index" style="margin-bottom:10px">
                    <el-collapse-item  :name="index">
                        <template slot="title">
                            <el-row type="flex" justify="space-between" align="middle" style="width:100%;padding-right:10px">
                                <div class="title" v-if="!selectWeek.includes(index)" style="color:#006666">{{item.month}}月
									<span style="font-size:15px"> {{item.begin_time}} - {{item.end_time}}</span>
								</div>
                                <div v-if="index < showCount" :class="[item.review == 2 ? 'topPlanActive' : 'reviewColor']">{{formData.status == 0 ? '月报未提交' : item.review == 1 ? '审核已通过' : item.review == 0?'月报暂未审核' : item.refuse_reason}}</div>
                                <div v-if="index < showCount">
									<!-- <el-row style="margin-top:20px;"  type="flex" justify="space-between"> -->
		                                <el-button v-if="userId && item.review !=1 && item.status == 1" type="primary" style="margin-left:auto" size="small" plain icon="el-icon-check" @click.stop="acceptCheck(item)">审核通过</el-button>
		                                <el-button v-if="userId && item.review !=1 && item.status == 1" type="danger" size="small" plain icon="el-icon-close" @click.stop="refuseCheck(item)">审核拒绝</el-button>
		                                <el-button v-if="!userId && item.status == 0" type="primary" style="margin-left:auto;" size="small" :disabled="item.done.length > 0 && item.plan.length > 0 ? false : true" icon="el-icon-check" @click.stop="submit(item)">提交月报</el-button>
		                            <!-- </el-row> -->
                                </div>
                            </el-row>
                        </template>
                        <el-row >
                            <!--本月总结-->
							<el-row class="tipBox flex flex-ai-c">
								<p class="title">{{item.month}}月总结</p>
                                <b style="background: rgba(0, 102 , 102 , 0.1);color:#747882">{{item.begin_time}} - {{item.end_time}}</b>
                            </el-row>
                            <el-table :data="item.done" ref="done" highlight-current-row border :cell-class-name="cellStyle"  :row-class-name="tableRowAddIndex" @row-click="(row,column,e)=>rowClick(row,column,e,index,'done')" :header-cell-style="{background:'#f5f5f5',color:'#515A6E'}" :span-method="item.doneColspan">
                                <el-table-column label="序号" width="50" align="center">
                                    <template slot-scope="scope">
                                        <div v-if="scope.row.id != 0">{{scope.$index+1}}</div>
                                        <div v-else style="height:20px"></div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="岗位职责" show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        <el-select style='width:100%' v-if="(scope.row.id != 0 && index < showCount) && ((item.status == 0 && !scope.row.plan_id) || (userId && item.review !=1) || (scope.row.add_status==2))" v-model="scope.row.class_name" allow-create size="small" filterable placeholder="岗位职责" @change="selectTypeBlur(scope.row,scope.row.class_name,'done')">
                                            <el-option v-for="(item,tIndex) in typeList" :key="tIndex" :label="item.name" :value="item.name"></el-option>
                                        </el-select>
                                        <el-row v-else>
                                            <el-tooltip v-if='scope.row.class_content' class="item" effect="dark" :content="scope.row.class_content" placement="top">
                                                <el-row >{{scope.row.class_name}}</el-row>
                                            </el-tooltip>
                                            <el-row v-else>{{scope.row.class_name}}</el-row>
                                        </el-row>
                                    </template>
                                </el-table-column>
                                <el-table-column label="工作安排">
                                    <template slot-scope="scope">
                                        <el-input v-if="(scope.row.id != 0 && index < showCount) && ((item.status == 0 && !scope.row.plan_id) || (userId && item.review !=1))" v-model="scope.row.content" type="textarea" :autosize="{ minRows:1}" resize="none"  @blur="save(scope.row,scope.row.content)" size="small" placeholder="工作安排"></el-input>
                                        <el-row v-else>{{scope.row.content}}</el-row>
                                    </template>
                                </el-table-column>
								<el-table-column label="结果定义">
                                    <template slot-scope="scope">
										<!--  && !scope.row.plan_id新加 -->
                                        <el-input v-if="(scope.row.id != 0 && index < showCount) && ((item.status == 0 && !scope.row.plan_id) || (userId && item.review !=1) || (scope.row.add_status==2))" v-model="scope.row.plan_result" type="textarea" :autosize="{ minRows:1}" resize="none" @blur="save(scope.row,scope.row.plan_result)" size="small" placeholder="结果定义"></el-input>
                                        <el-row v-else>{{scope.row.plan_result}}</el-row>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="name" label="计划完成" width="140" show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        <!-- <el-popover v-model="scope.row.popVisible" v-if="(scope.row.id != 0 && index < showCount) && ((item.status == 0 && !scope.row.plan_id) || (userId && item.review !=1) || (scope.row.add_status==2))" placement="bottom" title="计划完成时间" width="200" trigger="click">
                                            <div class="week_popover">
                                                <div class="flex flex-wrap weekDate">
                                                    <el-date-picker v-model="scope.row.finish_date" value-format="yyyy-MM-dd" format="yyyy年MM月dd日" size="small" type="date" placeholder="选择日期" @blur="yujiDate(1,scope.$index,scope.row.finish_date,scope.row)"></el-date-picker>
                                                </div>
                                                <div class="flex flex-jc-sa weekDate_bottom">
                                                    <span :class="[scope.row.plan_type == 2 ? 'selectActive' : '']" @click="yujiDate(2,scope.$index,'日常工作')">日常工作</span>
                                                    <span :class="[scope.row.plan_type == 3 ? 'selectActive' : '']" @click="yujiDate(3,scope.$index,'跨月工作')">跨月工作</span>
                                                </div>
                                            </div>
                                            <el-input v-model="scope.row.plan_real" readonly size="small" @focus="scope.row.plan_type == 1 ? scope.row.finish_date=scope.row.plan_real : scope.row.finish_date = ''"  placeholder="计划完成" slot="reference"></el-input>
                                        </el-popover> -->
                                        <el-row v-if="(scope.row.id != 0 && index < showCount) && ((item.status == 0 && !scope.row.plan_id) || (userId && item.review !=1) || (scope.row.add_status==2))">
                                            <el-date-picker class="pickerClass" style="width:100%" :editable="false" :clearable="false"  v-model="scope.row.plan_real" value-format="yyyy-MM-dd" format="yyyy-MM-dd" size="small" type="date" placeholder="选择日期" @blur="yujiDate(1,scope.$index)"></el-date-picker>
                                        </el-row>

                                        <!-- <el-date-picker class="pickerClass" style="width:100%" v-else readonly v-model="scope.row.plan_real"></el-date-picker> -->
                                        <el-row v-else>
                                            <i v-if="scope.row.id != 0" class="el-icon-date" style="color: #C0C4CC;margin-right:3px"></i>
                                            {{scope.row.plan_real}}
                                        </el-row>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="name" label="实际完成" width="130" show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        <el-popover v-model="scope.row.popFinishVisible" v-if="(scope.row.id != 0 && index < showCount) && ((item.status == 0) || (userId && item.review !=1) || (scope.row.add_status==2))" placement="bottom" title="实际完成进度" width="320" trigger="click">
                                            <div class="week_popover">
                                                <div v-for="fItem in weekFinishDate" class="flex flex-wrap finishDate" style="margin-bottom:15px">
                                                    <label>{{fItem.title}}：</label>
                                                    <div v-if="fItem.id != 2">
                                                        <el-date-picker v-if="fItem.id == 5" v-model="scope.row.zhuan_date" value-format="yyyy-MM-dd" format="yyyy年MM月dd日" style="margin-left:10px" size="small" type="date" placeholder="选择推迟日期" @blur="planSelectDate(scope.row,scope.row.zhuan_date,scope.$index,5)"></el-date-picker>
                                                        <el-date-picker v-if="fItem.id == 1" v-model="scope.row.finish_date" value-format="yyyy-MM-dd" format="yyyy年MM月dd日" style="margin-left:10px" size="small" type="date" placeholder="选择已完成日期" @blur="planSelectDate(scope.row,scope.row.finish_date,scope.$index,1)"></el-date-picker>
                                                        <span v-else v-for="date in fItem.arr" :class="[(scope.row.done_type == 3) && date.name == scope.row.done_real ? 'selectActive' : '']"  @click="finishDate(date.id,scope.$index,date.name)">{{date.name}}</span>
                                                    </div>
                                                    <div v-else style="padding-left:10px">
                                                        已完成
                                                        <el-select v-model="progress" size="small"  placeholder="进度" style="width:80px" @change="scope.row.done_real = progress;scope.row.done_type=2;scope.row.popFinishVisible=false;save(scope.row,scope.row.done_type)">
                                                            <el-option v-for="item in fItem.arr" :key="item" :label="item" :value="item"></el-option>
                                                        </el-select>
                                                        ，下月继续
                                                    </div>
                                                </div>
                                            </div>
                                            <el-input v-model="scope.row.done_real"  readonly size="small" placeholder="实际完成" slot="reference" @focus="actualFinshTime(scope.row)"></el-input>
                                        </el-popover>
                                        <el-row v-else>{{scope.row.done_real}}</el-row>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="name" label="完成情况说明">
                                    <template slot-scope="scope">
                                        <el-input v-if="(scope.row.id != 0 && index < showCount) && ((item.status == 0) || (userId && item.review !=1) || (scope.row.add_status==2))" v-model="scope.row.remark" @blur="save(scope.row,scope.row.remark)" type="textarea" :autosize="{ minRows:1}" resize="none" size="small" placeholder="完成情况说明"></el-input>
                                        <el-row v-else>{{scope.row.remark}}</el-row>
                                    </template>
                                </el-table-column>
                                <el-table-column label="未完成原因">
                                    <template slot-scope="scope">
										<el-input v-if="(scope.row.id != 0 && index < showCount) && ((item.status == 0) || (userId && item.review !=1) || (scope.row.add_status==2))" v-model="scope.row.undone" @blur="save(scope.row,scope.row.undone)" type="textarea" :autosize="{ minRows:1}" resize="none" size="small" placeholder="未完成原因"></el-input>
	                                    <el-row v-else>{{scope.row.undone}}</el-row>
                                    </template>
                                </el-table-column>
                                <el-table-column label="解决措施">
                                    <template slot-scope="scope">
										<el-input v-if="(scope.row.id != 0 && index < showCount) && ((item.status == 0) || (userId && item.review !=1) || (scope.row.add_status==2))" v-model="scope.row.solve" @blur="save(scope.row,scope.row.solve)" type="textarea" :autosize="{ minRows:1}" resize="none" size="small" placeholder="解决措施"></el-input>
	                                    <el-row v-else>{{scope.row.solve}}</el-row>
                                    </template>
                                </el-table-column>
                            </el-table>

                            <!--下月计划-->
                            <el-row class="tipBox flex flex-ai-c" style="margin-top:40px;">
								<p class="title">{{item.month+1}}月计划</p>
                                <b style="background:#006666">{{item.plan_begin_time}} - {{item.plan_end_time}}</b>
                            </el-row>

                            <el-table :data="item.plan" ref="plan" border style="width: 100%" highlight-current-row :cell-class-name="cellStyle" :row-class-name="tableRowAddIndex" @row-click="(row,column,e)=>rowClick(row,column,e,index,'plan')"  :header-cell-style="{background:'rgba(0, 102 , 102 , 0.1)',borderColor:'#e5e5e5',color:'#515a6e'}" :span-method="item.planColspan">
                                <el-table-column label="序号" width="50" align="center">
                                    <template slot-scope="scope">
                                        <div v-if="scope.row.id != 0">{{scope.$index+1}}</div>
                                        <div v-else style="height:20px"></div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="岗位职责" width="200" show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        <el-select style='width:100%' v-if="(scope.row.id != 0 && index < showCount) && ((item.status == 0) || (userId && item.review !=1))" v-model="scope.row.class_name" size="small" filterable allow-create placeholder="岗位职责" @change="selectTypeBlur(scope.row,scope.row.class_name,'plan')">
                                            <el-option v-for="(item,tIndex) in typeList" :key="'s'+tIndex" :label="item.name" :value="item.name"></el-option>
                                        </el-select>
                                        <el-row v-else>
                                            <el-tooltip v-if='scope.row.class_content' class="item" effect="dark" :content="scope.row.class_content" placement="top">
                                                <el-row >{{scope.row.class_name}}</el-row>
                                            </el-tooltip>
                                            <el-row v-else>{{scope.row.class_name}}</el-row>
                                        </el-row>
                                        <!-- <el-input v-if="scope.row.id != 0 && index == 0 && item.done_status == 0 && !userId" v-model="scope.row.class_name" @blur="save(scope.row,scope.row.class_name)" size="small" placeholder="岗位职责"></el-input> -->
                                        <!-- <el-row v-else>{{scope.row.class_name}}</el-row> -->
                                    </template>
                                </el-table-column>
                                <el-table-column label="工作安排">
                                    <template slot-scope="scope">
                                        <el-input v-if="(scope.row.id != 0 && index < showCount) && ((item.status == 0) || (userId && item.review !=1))" v-model="scope.row.content" type="textarea" :autosize="{ minRows:1}" resize="none" @blur="save(scope.row,scope.row.content)" size="small" placeholder="工作安排"></el-input>
                                        <el-row v-else>{{scope.row.content}}</el-row>
                                    </template>
                                </el-table-column>
                                <el-table-column label="结果定义">
                                    <template slot-scope="scope">
                                        <el-input v-if="(scope.row.id != 0 && index < showCount) && ((item.status == 0) || (userId && item.review !=1))" v-model="scope.row.plan_result" type="textarea" :autosize="{ minRows:1}" resize="none" @blur="save(scope.row,scope.row.plan_result)" size="small" placeholder="工作安排"></el-input>
                                        <el-row v-else>{{scope.row.plan_result}}</el-row>
                                    </template>
                                </el-table-column>
                                <el-table-column label="计划完成" width="140" show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        <!-- <el-popover v-model="scope.row.popVisible" v-if="(scope.row.id != 0 && index < showCount) && ((item.status == 0) || (userId && item.review !=1)) " placement="bottom" title="计划完成时间" width="200" trigger="click">
                                            <div class="week_popover">
                                                <div class="flex flex-wrap weekDate">
                                                    <el-date-picker v-model="scope.row.finish_date" @focus='focusPicker(scope.row)' value-format="yyyy-MM-dd" format="yyyy年MM月dd日" size="small" type="date" placeholder="选择日期" @blur="yujiDate(1,scope.$index,scope.row.finish_date,scope.row)"></el-date-picker>
                                                </div>
                                                <div class="flex flex-jc-sa weekDate_bottom">
                                                    <span :class="[scope.row.plan_type == 2 ? 'selectActive' : '']" @click="yujiDate(2,scope.$index,'日常工作')">日常工作</span>
                                                    <span :class="[scope.row.plan_type == 3 ? 'selectActive' : '']" @click="yujiDate(3,scope.$index,'跨月工作')">跨月工作</span>
                                                </div>
                                            </div>
                                            <el-input v-model="scope.row.plan_real" readonly size="small" placeholder="计划完成" slot="reference" @focus="finishFocus(scope.row)"></el-input>
                                        </el-popover>
                                        <el-row v-else>{{scope.row.plan_real}}</el-row> -->
                                        <el-row v-if="(scope.row.id != 0 && index < showCount) && ((item.status == 0 && !scope.row.plan_id) || (userId && item.review !=1) || (scope.row.add_status==2))">
                                            <el-date-picker class="pickerClass" :editable="false" :clearable="false" style="width:100%" v-model="scope.row.plan_real" value-format="yyyy-MM-dd" format="yyyy-MM-dd" size="small" type="date" placeholder="选择日期" @blur="yujiDate(1,scope.$index)"></el-date-picker>
                                        </el-row>

                                        <!-- <el-date-picker class="pickerClass" style="width:100%" v-else readonly v-model="scope.row.plan_real"></el-date-picker> -->
                                        <el-row v-else>
                                            <i v-if="scope.row.id != 0" class="el-icon-date" style="color: #C0C4CC;margin-right:3px"></i>
                                            {{scope.row.plan_real}}
                                        </el-row>
                                    </template>
                                </el-table-column>
                                <!-- <el-table-column prop="name" label="接收人" show-overflow-tooltip></el-table-column> -->
                                <!-- <el-table-column v-if="$root.userInfo.parent_id != 0" prop="name" label="上级回复" show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        <el-input v-if="scope.row.id != 0 && index==0" v-model="scope.row.up_reply" @blur="save(scope.row,scope.row.up_reply)" size="small" placeholder="岗位职责"></el-input>
                                        <el-row v-else>{{scope.row.up_reply}}</el-row>
                                    </template>
                                </el-table-column> -->
                            </el-table>

                        </el-row>
                    </el-collapse-item>
                </el-card>
            </el-collapse>

			<div style="margin:50px 0" class="flex">
				<el-button v-if="isWeek==2" size="small" plain type="primary" style="width:50%;" @click="preWeek()">前一月月报</el-button>
				<el-button v-if="isWeek==2" size="small" plain type="primary" style="width:50%;" @click="init()">只看当前月</el-button>
			</div>
            <el-empty v-if="isWeek==1" description="暂无数据"></el-empty>
            <!--留言抽屉-->
            <drawerMessage ref="drawerMessage" ></drawerMessage>
            <!--月报抽屉-->
            <drawerMonth ref="drawerMonth" ></drawerMonth>
        </div>
    </div>
</template>

<script>
import drawerMessage from "@/components/drawerMessage.vue"; //留言抽屉
import drawerMonth from "@/components/drawerMonth.vue"; //月报抽屉

export default {
    components: {
        drawerMessage,
        drawerMonth
    },
    data() {
        return {
            formData: {},
            weekArray: [], //跨月报列表
            typeList: [], //岗位职责

            selectWeek: [], //打开id
            clickWeekArray: [],
            rowClickIndex: {}, //点击当前行数据
            clickRow: {},
            colors: ['#006666', '#006666', '#006666'],
            weekFinishDate: [{
                id: 1,
                title: '已完成',
                arr: []
            }, {
                id: 2,
                title: '努力中',
                arr: ['10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%'],
            }, {
                id: 3,
                title: '未开始',
                arr: [{
                    id: 3,
                    name: '下月开始'
                }, {
                    id: 4,
                    name: '任务取消'
                }]
            }, {
                id: 5,
                title: '计划推迟',
            }],


            count: 1,
            progress: '',
            userId: '',
            title: '前一月月报',
            hoverIndex: '',

            isShowMenu: false, //编辑框状态
            delDistable: true, //删除按钮状态
            sortUpDistable: true, //排序按钮状态
            sortDownDistable: true, //排序按钮状态
            nextWeekDistable: true, //总结转到下月
            isEmpty: false,

            isWeek: '',
            showCount: 2
        }
    },


    watch: {
        $route() {
            this.isEmpty = false;
            this.init();
        }
    },

    mounted() {
        this.init();
    },

    methods: {
        init: function () {
            this.userId = this.$route.query.id ? this.$route.query.id : '';
            this.count = 1;
            this.getList();
            this.getType();
        },

        //月报列表
        getList: function () {
            this.http.post('/admin.workMonth/getList', {
                uid: this.userId,
                count: this.count
            }).then(response => {
                this.formData = response;
                this.isWeek = response.list.length == 0 ? 1 : 2;
                this.weekArray = response.list;
                // 表格岗位职责合并
                this.handleColspan(this.weekArray);
                //el-collapse绑定默认值展开还是关闭
                this.editSelectWeek();

                for (let index in response.list) {
                    //存在下级只能查看不能添加
                    if (((!this.userId && response.list[index].status == 0) || (this.userId && response.list[index].review != 1 && response.list[index].done.length > 0 && response.list[index].plan.length > 0))) {
                        this.weekArray[index].done.push({ id: 0 })
                        this.weekArray[index].plan.push({ id: 0 })
                    }

                    //添加类型
                    response.list[index].done.forEach((dItem) => {
                        this.$set(dItem, 'type', 'done')
                    })
                    response.list[index].plan.forEach((pItem) => {
                        this.$set(pItem, 'type', 'plan')
                    })
                }

                //计划完成时间popover初始化状态
                this.popoverDisabled();
            })
        },
        // 表格岗位职责合并的方法
        handleColspan(list) {
            list.forEach((week, weekindex) => {
                if (week.done_class_merge == 1) {
                    week.doneColspan = function ({ row, column, rowIndex, columnIndex }) {
                        let spanArr = [] // 页面展示的数据，不一定是全部的数据，所以每次都清空之前存储的 保证遍历的数据是最新的数据。以免造成数据渲染混乱
                        let pos = 0
                        let data = week.done
                        //遍历数据
                        data.forEach((item, index) => {
                            if (index == 0) { //判断是否是第一项（取决于你准备合并表格第几项）
                                spanArr.push(1)
                                pos = 0
                            } else { //不是第一项时，就根据标识去存储
                                if (data[index].class_name === data[index - 1].class_name) { // 查找到符合条件的数据时每次要把之前存储的数据+1
                                    spanArr[pos] += 1
                                    spanArr.push(0)
                                } else { // 没有符合的数据时，要记住当前的index
                                    spanArr.push(1)
                                    pos = index
                                }
                            }
                        })
                        if (columnIndex === 1) {
                            const _row = spanArr[rowIndex]
                            const _col = _row > 0 ? 1 : 0
                            return {
                                rowspan: _row,
                                colspan: _col
                            }
                        } else {
                            return false
                        }
                    }
                }
                if (week.plan_class_merge == 1) {
                    week.planColspan = function ({ row, column, rowIndex, columnIndex }) {
                        let spanArr = [] // 页面展示的数据，不一定是全部的数据，所以每次都清空之前存储的 保证遍历的数据是最新的数据。以免造成数据渲染混乱
                        let pos = 0
                        let data = week.plan
                        //遍历数据
                        data.forEach((item, index) => {
                            if (index == 0) { //判断是否是第一项（取决于你准备合并表格第几项）
                                spanArr.push(1)
                                pos = 0
                            } else { //不是第一项时，就根据标识去存储
                                if (data[index].class_name === data[index - 1].class_name) { // 查找到符合条件的数据时每次要把之前存储的数据+1
                                    spanArr[pos] += 1
                                    spanArr.push(0)
                                } else { // 没有符合的数据时，要记住当前的index
                                    spanArr.push(1)
                                    pos = index
                                }
                            }
                        })
                        if (columnIndex === 1) {
                            const _row = spanArr[rowIndex]
                            const _col = _row > 0 ? 1 : 0
                            return {
                                rowspan: _row,
                                colspan: _col
                            }
                        } else {
                            return false
                        }
                    }
                }
            })
        },
        //分类列表
        getType: function () {
            this.http.post('/admin.work/getClass', {
                uid: this.userId
            }).then(response => {
                this.typeList = response;
            })
        },

        //el-collapse绑定默认值展开还是关闭
        editSelectWeek: function () {
            this.selectWeek = [];
            this.weekArray.forEach((item, index) => {
                this.selectWeek.push(index)
            });
        },
        addDoneRow: function (name) {
            if (this.weekArray[0][name].length == 0) {
                this.weekArray[0][name].push(this.addParam())
            }
        },
        addParam: function () {
            let arr = {
                class_name: '',
                content: '',
                plan_real: '',
                plan_type: 0,
                done_real: '',
                done_type: 0,
                remark: '',
                up_mark: 0
            }
            return arr;
        },

        focusPicker: function (row) {
            if (!row.plan_real) {
                let month = parseInt(Number(this.weekArray[this.rowClickIndex.index].month) + Number(1)) > 12 ? 1 : parseInt(Number(this.weekArray[this.rowClickIndex.index].month) + Number(1));
                var time = new Date().getFullYear() + '-' + month + '-' + '01';
                this.$set(row, 'finish_date', time)
            }
        },

        finishFocus: function (row) {
            // if(row.plan_real){
            if (row.plan_real && row.plan_real != '日常工作' && row.plan_real != '跨月工作') {
                row['finish_date'] = row.plan_real;
            }
        },

        //popover赋值
        popoverDisabled: function () {
            this.weekArray.forEach((item) => {
                item.done.forEach((zItem) => {
                    this.$set(zItem, 'popVisible', false);
                    this.$set(zItem, 'popFinishVisible', false)
                });
                item.plan.forEach((zItem) => {
                    this.$set(zItem, 'popVisible', false);
                });
            })
        },

        //前一月月报
        preWeek: function () {
            if (this.isEmpty) return;
            this.count += 1;
            this.http.post('/admin.workMonth/show', {
                weekCount: this.count
            }).then(response => {
                if (!response) {
                    this.$message.error('前一月暂无数据');
                    this.isEmpty = true;
                };
                // 表格岗位职责合并
                this.handleColspan([response]);
                this.weekArray.push(response);
                this.editSelectWeek();
            })
        },

        //选择计划完成
        yujiDate: function (type, rowIndex) {
            //控制popover显示隐藏
            // this.clickWeekArray[rowIndex].popVisible = false;
            //计划完成类型
            this.$set(this.clickWeekArray[rowIndex], 'plan_type', type);
            //计划完成内容
            // this.$set(this.clickWeekArray[rowIndex], 'plan_real', date);

            this.save(this.clickRow, type);
        },



        //计划推迟选择日期
        planSelectDate: function (row, column, rowIndex, type) {
            //控制popover显示隐藏
            this.clickWeekArray[rowIndex].popFinishVisible = false;
            row.done_real = '';
            row.done_real = column ? column : this.clickRow.done_real;
            //实际完成类型
            this.$set(this.clickWeekArray[rowIndex], 'done_type', type);

            this.save(this.clickRow, row.done_real);
        },

        //实际完成时间获得焦点初始化值
        actualFinshTime: function (row) {
            this.progress = row.done_type == 2 ? row.done_real : '';
            row.zhuan_date = row.done_type == 5 ? row.done_real : '';
            row.finish_date = row.done_type == 1 ? row.done_real : '';
        },

        //选择实际完成
        finishDate: function (type, rowIndex, date) {
            //控制popover显示隐藏
            this.clickWeekArray[rowIndex].popFinishVisible = false;
            //实际完成类型
            this.$set(this.clickWeekArray[rowIndex], 'done_type', type);
            //实际完成内容
            this.$set(this.clickWeekArray[rowIndex], 'done_real', date);
            this.save(this.clickRow, type)
        },

        //选择分类
        selectTypeBlur: function (param, value, type) {
            param.class_name = value;
            this.save(param, param.class_name, type);
        },

        //点击行添加索引值
        tableRowAddIndex: function ({
            row,
            rowIndex
        }) {
            row.index = rowIndex;
        },

        //设置优先级序号添加样式
        cellStyle: function ({
            row,
            column,
            rowIndex,
            columnIndex
        }) {
            //总结未完成
            if (row.type == "done" && row.done_type && row.done_type != 1 && row.id != 0) {
                return columnIndex == 0 ? 'noFinish' : 'noFinishFC'
            }

            //优先级高亮
            if (row.priority == 2) {
                return columnIndex == 0 ? 'active' : 'activeFC'
            }

            //总结新增
            if (!row.plan_id && row.id != 0 && row.type == 'done') {
                return columnIndex == 0 ? 'addPlan' : 'addPlanFC'
            }

            //上级添加总结计划
            if (row.add_status == 2 || row.add_status == 3) {
                return columnIndex == 0 ? 'reviewColorTable' : 'reviewColorTableFC'
            }

            //审核拒绝再次添加总结计划高亮显示
            if (row.add_type == 2) {
                return columnIndex == 0 ? 'addHighColor' : 'addHighColorFC'
            }
        },

        //设置优先级数据
        setPriority: function () {
            this.http.post('/admin.workMonth/priority', {
                id: this.clickRow.id,
                type: this.rowClickIndex.name,
                priority: this.clickRow.priority == 1 ? 2 : 1
            }).then(() => {
                this.isShowMenu = false;
                this.getList();
            })
        },

        //结转下月
        nextWeek: function () {
            this.$confirm(`确定结转下月吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.http.post('/admin.workMonth/nextWeek', {
                    id: this.clickRow.id
                }).then(() => {
                    this.$message.success('已转为下月计划！');
                    this.getList();
                })
            })
        },


        //点击本月总结行
        rowClick: function (row, column, event, index, name) {
            //点击表格行
            this.clickRow = row;
            //点击月
            this.clickWeekArray = this.weekArray[index][name];
            //判断当前月显示编辑框
            this.isShowMenu = (index == 0 && this.weekArray[index].status == 0 && !this.clickRow.plan_id) || (this.userId && this.weekArray[index].review != 1) ? true : false;
            //点击当前行的索引值
            this.rowClickIndex = {
                index: index, //点击月的索引
                rowIndex: row.index, //点击总结索引
                name: name
            };

            //菜单栏判断
            this.menuDistable();

            //点击最后一行添加一条新数据
            if (row.id == 0) {
                this.clickWeekArray.splice(this.clickWeekArray.length - 1, 0, this.addParam())
            }
        },

        //菜单按钮判断
        menuDistable: function () {
            //删除数组最后一行
            let listTemp = this.clickWeekArray.slice(0, -1);
            //删除按钮
            if (!this.clickRow.class_name && !this.clickRow.done_type && !this.clickRow.content && !this.clickRow.plan_type && !this.clickRow.remark) {
                this.delDistable = true;
                this.sortUpDistable = true;
                this.sortDownDistable = true;
                this.nextWeekDistable = true;
            } else {
                this.delDistable = false;
                //向上排序按钮
                this.sortUpDistable = this.clickRow.index > 0 ? false : true;
                //向下排序按钮
                this.sortDownDistable = this.clickRow.index < listTemp.length - 1 ? false : true;
                //结转下月
                this.nextWeekDistable = this.rowClickIndex.name == 'done' ? false : true;
            }
        },

        // 排序向上
        sortUp: function () {
            if (this.clickRow.index > 0) {
                //复制
                var copy = JSON.parse(JSON.stringify(this.clickRow));
                //删除
                this.clickWeekArray.splice(this.clickRow.index, 1);
                //插入
                this.clickWeekArray.splice(this.clickRow.index - 1, 0, copy);

                this.$refs[this.rowClickIndex.name][0].setCurrentRow(copy)

                this.sortRequest(this.clickWeekArray);

                this.clickRow['index'] = this.clickRow.index - 1;
                //菜单按钮状态
                this.menuDistable();
            }
        },

        // 排序向下
        sortDown: function () {
            let listTemp = this.clickWeekArray.slice(0, -1); //去除最后添加的一行表格
            if (this.clickRow.index < listTemp.length - 1) {
                //复制
                var copy = JSON.parse(JSON.stringify(this.clickRow));
                //删除
                this.clickWeekArray.splice(this.clickRow.index, 1);
                //插入
                this.clickWeekArray.splice(this.clickRow.index + 1, 0, copy);

                this.$refs[this.rowClickIndex.name][0].setCurrentRow(copy);
                //调接口
                this.sortRequest(this.clickWeekArray);
                //排序后更改索引值
                this.clickRow['index'] = this.clickRow.index + 1;

                this.menuDistable();
            }
        },

        //排序接口
        sortRequest: function (list) {
            var submitList = [];
            for (var i in list) {
                if (list[i].id && list[i].id != 0) {
                    submitList.push({
                        sort: i,
                        id: list[i].id,
                    });
                }
            }

            this.http.post('/admin.workMonth/sort', {
                type: this.rowClickIndex.name,
                sort: submitList
            }, false)
        },

        //删除总结or计划
        habdleDel: function () {
            this.$confirm(`删除后不可恢复，确定删除吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                //删除选中当前行
                this.clickWeekArray.splice(this.rowClickIndex.rowIndex, 1);
                //若只新增没添加则禁止不调取接口
                if (!this.clickRow.id) {
                    this.isShowMenu = false;
                    return
                };

                this.http.post('/admin.workMonth/delete', {
                    id: this.clickRow.id,
                    type: this.rowClickIndex.name == 'done' ? 1 : 2,
                    uid: this.userId
                }).then(() => {
                    this.$message.success('操作成功！');
                    this.isShowMenu = false;
                    this.getList();
                })
            })
        },

        //通过审核
        acceptCheck: function (item) {
            this.$confirm(`确定通过审核吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.checkRequest(1, '', item)
            })
        },

        //拒绝审核
        refuseCheck: function (item) {
            this.$prompt('请输入拒绝原因', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /\S/,
                inputErrorMessage: '请输入拒绝原因'
            }).then(({
                value
            }) => {
                this.checkRequest(2, value, item);
            }).catch(() => {
                this.$message('取消输入');
            });
        },

        //审核接口
        checkRequest: function (state, value, item) {
            this.http.post('/admin.workMonth/submitUpd', {
                uid: this.userId,
                review: state,
                refuse_reason: value,
                date: item.begin_time
            }).then(() => {
                this.$message.success('提交成功');
                this.isShowMenu = false;
                this.getList();
            })
        },

        jumpWeek: function () {
            this.$router.go(-1);
        },

        //实时保存数据
        save: function (param, content, type) {
            param['type'] = type ? type : this.rowClickIndex.name;
            param['uid'] = this.userId;
            param['date'] = this.weekArray[this.rowClickIndex.index].begin_time;
            if (!content) return;
            this.http.post('/admin.workMonth/save', param, false).then(res => {
                param['id'] = res.id;
                //选择未完成更新下月计划
                if (param.type == 'done') {
                    this.weekArray[this.rowClickIndex.index].plan = res.list;
                    this.weekArray[this.rowClickIndex.index].plan.push({
                        id: 0
                    });
                }
            })
        },

        //提交月报
        submit: function (param) {
            let weekDone = param.done.slice(0, -1).filter(i => (i.class_name || i.content || i.plan_type || i.done_type));//去除最后一条
            let weekPlan = param.plan.slice(0, -1).filter(i => (i.class_name || i.content || i.plan_result || i.plan_type));//去除最后一条
            //本周总结
            if (weekDone.length == 0) return this.$message.error('请填写本月总结！');
            if (weekPlan.length == 0) return this.$message.error('请填写下月计划！')
            if (weekDone.length != weekDone.filter(i => i.class_name).length) return this.$message.error('本月总结岗位职责不得为空！');
            if (weekDone.length != weekDone.filter(i => i.content).length) return this.$message.error('本月总结工作安排不得为空！');
            if (weekDone.length != weekDone.filter(i => i.plan_type).length) return this.$message.error('本月总结计划完成时间不得为空！');
            if (weekDone.length != weekDone.filter(i => i.done_type).length) return this.$message.error('本月总结实际完成时间不得为空！');
            //下周计划
            if (weekPlan.length != weekPlan.filter(i => i.class_name).length) return this.$message.error('下月计划岗位职责不得为空！');
            if (weekPlan.length != weekPlan.filter(i => i.content).length) return this.$message.error('下月工作安排不得为空！');
            if (weekPlan.length != weekPlan.filter(i => i.plan_result).length) return this.$message.error('结果定义不得为空！');
            if (weekPlan.length != weekPlan.filter(i => i.plan_type).length) return this.$message.error('下月计划计划完成时间不得为空！');

            this.$confirm(`提交后不可修改，确定提交吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.http.post('/admin.workMonth/submitSave', { uid: this.userId, date: param.begin_time }).then(() => {
                    this.$message.success('月报提交成功！');
                    this.isShowMenu = false;
                    this.getList();
                })
            })
        }
    }
}
</script>
<style media="screen" lang="less">
.pickerClass {
    input {
        padding-right: 0 !important;
        padding-left: 20px !important;
    }
    .el-input__prefix {
        left: -6px !important;
    }
}
.el-table .active {
    width: 3px;
    height: 100%;
    background: #ff7300 !important;
    color: #fff !important;
}

.el-table .activeFC {
    color: #ff7300 !important;

    input,
    textarea {
        color: #ff7300 !important;
    }
}

.selectActive {
    background: #e5f3f3;
}

.topPlanActive {
    color: rgba(179, 0, 4, 1);
    font-weight: bold;
    font-size: 14px;
}

.reviewColor {
    color: #006666;
    font-weight: bold;
    font-size: 14px;
}
::v-deep .el-table__body tr.current-row > td {
    background: rgba(0, 102, 102, 0.1) !important;
}

.reviewColorTable {
    background: #3aafff !important;
    color: #fff !important;
}

.reviewColorTableFC {
    color: #3aafff !important;

    input,
    textarea {
        color: #3aafff !important;
    }
}

.addPlan {
    background: #11bd8d !important;
    color: #fff !important;
}
.addPlanFC {
    color: #11bd8d !important;
    input,
    textarea {
        color: #11bd8d !important;
    }
}
.noFinish {
    background: #f76363 !important;
    color: #fff !important;
}
.noFinishFC {
    color: #f76363 !important;
    input,
    textarea {
        color: #f76363 !important;
    }
}

.addHighColor {
    background: #008685 !important;
    color: #fff !important;
}
.addHighColorFC {
    color: #008685 !important;
    input,
    textarea {
        color: #008685 !important;
    }
}

.addHighColor {
    color: #008685;
}
</style>
